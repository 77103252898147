
import {defineComponent, ref, reactive, onMounted, watch, createVNode} from 'vue';
import { useStore } from 'vuex';
import { SearchConfig } from '@/components/page-model/typing';
import { useForm } from 'ant-design-vue/es/form';

import {message, Modal, notification} from "ant-design-vue";
import modalBox from "@/components/form-modal/modal-tools";
import {get_plan_excel_import_setting_by_flag, set_plan_excel_import_setting_by_flag} from "@/api/sys-model";
import {bulk_create_track_point} from "@/api/alarm-model";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import ls from "@/utils/local-storage";
import {CONFIGURATION_FILE, ROLL_NO} from "@/store/mutation-type";
export default defineComponent({
  setup() {
    const loading = ref(false);
    const is_table = ref(false);
    const store = useStore();
    const confirmLoading = ref<boolean>(false);
    const mark_set: any = ref(null);
    const json: any = ref({});
    const json_num: any = ref(6);
    const current_org = store.getters['user/current_org'];
    const search_config: SearchConfig = {
      settings: {
        labelAlign: 'right',
        layout: 'inline',
      },
      fields: [
        {
          type: 'input',
          name: 'roll_no_start',
          label: '轧制序号',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写轧制序号',
        },
      ],
      rules: reactive({}),
      model: reactive({
        org_id: current_org?.id,
        aim_org_id: current_org?.id,
        is_active: true,
        roll_no_start: null,
      }),
    };
    const production_record_item_search = reactive({
      current: 1,
      pageSize: 2000,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        aim_org_id: current_org.id,
        only_temp: false,
        is_change: 1,
        roll_no_start: search_config.model.roll_no_start,
      },
    });
    const tableHeight = reactive({ y: window.innerHeight - 280 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 280;
      };
    });
    const page = reactive({
      title: '',
      column_flag: 'configuration_file',
      search: {
        ...production_record_item_search,
        requestParams: { ...production_record_item_search.requestParams }
      },
      modalType: 'production_manage-configuration_file',
      height: tableHeight.y,
      mode: 'null',
      data_change_timeline: 1,
      column_change_timeline: 1,
    });

    const search_options = {};
    const { resetFields, validateInfos } = useForm(search_config.model, search_config.rules);
    const get_json_zhengshi = () => {
      json_num.value = 6;
      get_plan_excel_import_setting_by_flag({
        org_id: current_org.id,
        flag: 'product_record_item_' + current_org.id,
      }).then((res: any) => {
        Object.assign(json.value, res);
        message.success('加载成功');
      });
      ls.set(CONFIGURATION_FILE, '');
    };
    get_json_zhengshi();
    const search = () => {
      if(search_config.model.roll_no_start){
        loading.value = true;
        is_table.value = true;
        notification.success({
          message: '提示',
          description: '加载时间较长，请耐心等待！',
        });
        ls.set(ROLL_NO, search_config.model.roll_no_start);
        page.search.requestParams.is_change += 1;
        page.column_change_timeline += 1;
        page.search.requestParams.roll_no_start = search_config.model.roll_no_start;
      }else{
        notification.error({
          message: '提示',
          description: '请填写 轧制序号',
        });
      }
    };

    const save_data_default = () => {
      Modal.confirm({
        title: '温馨提示，覆盖后不可恢复，请慎重！',
        content: '确认将【备份' + json_num.value + '】替换正式脚本！',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          console.log('json.value', json.value);
          return new Promise(resolve => {
            set_plan_excel_import_setting_by_flag({
              org_id: current_org?.id,
              flag: 'product_record_item_' + current_org.id,
              data: json.value,
            })
              .then(() => {
                message.success('已经更新配置文件：备份' + json_num.value);
                const data_list: any = [];
                json.value.head_list.map((item: any) => {
                  data_list.push({
                    opc_name: item.flag,
                    name: item.name,
                    value_type: item.value_type,
                  })
                })
                bulk_create_track_point({
                  org_id: current_org?.id,
                  data_list: JSON.stringify(data_list),
                }).then(() => {

                })
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    }
    const get_json = (index: any) => {
      json_num.value = index;
      ls.set(CONFIGURATION_FILE, index);
      // page.column_change_timeline += 1;
      // page.data_change_timeline += 1;
      if(index){
        index = '_' + index;
      }else {
        index = '';
      }
      get_plan_excel_import_setting_by_flag({
        org_id: current_org.id,
        flag: 'product_record_item_' + current_org.id + index,
      }).then((res: any) => {
        json.value.length = 0;
        Object.assign(json.value, res);
        message.success('加载成功');
      })
    }
    const go_mark_set = () => {
      const editModal = reactive({
        visible: true,
        loading: false,
        component: 'configurationFileModal',
        json: json.value,
        ok: (num: any) => {
          if(num){
            num = '_' + num;
          }else {
            num = '';
          }
          get_plan_excel_import_setting_by_flag({
            org_id: current_org.id,
            flag: 'product_record_item_' + current_org.id + num,
          }).then((res: any) => {
            json.value.length = 0;
            Object.assign(json.value, res);
            message.success('加载成功');
          })
        },
      });
      modalBox(editModal);
    };
    return {
      loading,
      confirmLoading,
      search_config,
      search_options,
      resetFields,
      mark_set,
      validateInfos,
      search,
      save_data_default,
      get_json_zhengshi,
      go_mark_set,
      get_json,
      json_num,
      is_table,
      tableHeight,
      page,
    };
  },
});
